import React from "react";
import MiaWallet from "./images/miaWallet.jpg";
import MatrixGame from "./images/memory-matrix-game.jpg";
import BrainGame from "./images/training-brain-game.jpg";
import TinDog from "./images/TinDog.png";
import Citizenship from "./images/Citizenship_quest.png";
import OutdoorGarage from "./images/outdoor-garage.png";

import { Card, Row, Col, Container } from "react-bootstrap";

export default function MyWork({ id, color }) {
  return (
    <div id={id} className={`my-work-section ${color}`}>
      <h2 className="title">Work Experience</h2>
      <Container fluid="md">
        <Row>
        <Col xs={12} md={12} lg={12} className={`my-work-section ${color}`}>
            <Card.Body>
              <Card.Title className="work-experience">Petfriendly - Software Engineer</Card.Title>
              <p>April 2021 - April 2024</p>
              <Card.Text>
                As a Software Engineer at PetFriendly, I collaborated and maintained the customer-facing website and internal custom software, providing ongoing support and implementing improvements for these platforms. With experience in full-stack development, I covered both back-end and front-end aspects, as well as API integrations.
                I demonstrated proficiency in understanding, implementing, and troubleshooting various third-party APIs and internal services, and actively participated in the documentation of APIs. I also developed and executed unit tests and integration tests to ensure the reliability and functionality of our solutions. Additionally, I collaborated closely with cross-functional teams on projects spanning the entire platform, from back-end development to front-end design. I thrived on continuous learning and constantly sought innovative approaches to deliver exceptional results.
                <i className="tech_used">
                  Technologies Used:‌ Laravel, PHP, JavaScript, RESTful APIS, MySql, Nova (administration panel PHP-Laravel).
                </i>
              </Card.Text>
              <a className="text-primary" href="https://www.linkedin.com/in/veroca/">
                More <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
                </Col>
              </a>
            </Card.Body>
          </Col>
        </Row>
        </Container>
      <h2 className="title">Some things I've build in the past</h2>
      <Container fluid="md">
        <Row>
        <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={OutdoorGarage} />
            <Card.Body>
              <Card.Title>Outdoor Garage Management System</Card.Title>
              <Card.Text>
                Built in 2024, The app, built with Laravel and integrated with Filament for administration and Stripe for subscriptions, manages outdoor garage spaces efficiently. It offers a user-friendly interface for property managers to oversee and monetize parking spaces, ensuring secure and seamless subscription management.
                <i className="tech_used">
                  Technologies Used:‌ ‌Laravel, Filament, Stripe, Tailwind CSS.
                </i>
              </Card.Text>
              <p className="text-primary">
                Private Project
              </p>
            </Card.Body>
          </Col>
        <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={Citizenship} />
            <Card.Body>
              <Card.Title>Q&A Citizenship App</Card.Title>
              <Card.Text>
                Built in 2021, multi-choice practice test application connected to an endpoint. It fetches all the official USCIS questions with multiple answers. The application displays answers by color and indicates whether they are correct or incorrect based on the selected choice and score.
                <i className="tech_used">
                  Technologies Used:‌ ‌Vue.js, Bootstrap-vue, customize Database, JSON.‌
                </i>
              </Card.Text>
              <a className="text-primary" href="https://citizenship-test-practice.netlify.app/">
                Visit project
              </a>
            </Card.Body>
          </Col>

          <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={MiaWallet} />
            <Card.Body>
              <Card.Title>Budgeting App</Card.Title>
              <Card.Text>
                Built in 2020, MiaWallet is a budgeting web application designed to help users create and manage budgets, track their income, and monitor their expenses.
                <i className="tech_used">
                  Technologies Used:‌ ‌JavaScript, Express.js, Node.js, MongoDB, Mongoose, Bootstrap, SASS, Heroku, Axios, Nodemailer, Moment.js.‌ ‌  ‌
                </i>
              </Card.Text>
              <p>Team Project</p>
              <a
                className="text-primary"
                href="https://github.com/veroca88/Ironhack-Project-2"
              >
                Visit project
              </a>
            </Card.Body>
          </Col>
          <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={TinDog} />
            <Card.Body>
              <Card.Title>HTML/CSS Responsive Web Page</Card.Title>
              <Card.Text>
                Built in 2019, This is an example of static Website with responsive design,
                it is built with plain HTML and CSS.
                <i className="tech_used">
                  Technologies Used:‌ ‌HTML, CSS, media queries.‌ ‌
                </i>
              </Card.Text>
              <a
                className="text-primary"
                href="https://veroca88.github.io/TinDog-webpage-prctc/"
              >
                Visit project
              </a>
            </Card.Body>
          </Col>

          <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={BrainGame} />
            <Card.Body>
              <Card.Title>Where did I put my keys?</Card.Title>
              <Card.Text>
              Built in 2019, this is a brain training game. The objective is to obtain the key by solving numerical reasoning tests along the way.
                <i className="tech_used">
                  Technologies Used:‌ ‌JavaScript, Canvas, Bootstrap, JQuery, HTML, CSS.‌ ‌
                </i>
              </Card.Text>
              <a
                className="text-primary"
                href="https://veroca88.github.io/project_1TheGame/"
              >
                Visit project
              </a>
            </Card.Body>
          </Col>

          <Col xs={12} md={8} lg={6} className={`my-work-section ${color}`}>
            <Card.Img variant="top" src={MatrixGame} />
            <Card.Body>
              <Card.Title>The Matrix Game</Card.Title>
              <Card.Text>
                Built in 2019, The Matrix is a memory game. It has the presentation page where
                is the instructions of the game and also where you can chose the
                leves, the design of the computer is pure CSS.
                <i className="tech_used">
                  Technologies Used:‌ ‌JavaScript, HTML, CSS.‌ ‌
                </i>
              </Card.Text>
              <a
                className="text-primary"
                href="https://veroca88.github.io/linux-memory-game/"
              >
                Visit project
              </a>
            </Card.Body>
          </Col>

        </Row>
      </Container>
    </div>
  );
}
