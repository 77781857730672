import React from "react";

export default function AboutMe({ id, color }) {
  return (
    <div className={color} id={id}>
      <div className="terminal">
        <div className="head">
          <h3> veronica:~/About-me</h3>
        </div>
        <p className="about-me">
        As a Software Engineer, I specialize in collaborating on and maintaining customer-facing
        websites and internal custom software solutions,
        I cover both back-end and front-end aspects, as well as API integrations.
        </p>
        <p className="about-me">My skills and experience include:</p>
        <p className="about-me">
        Collaboration with cross-functional teams on projects that encompass the entire software platform, from back-end architecture to front-end design.
        Development and execution of unit tests and integration tests to guarantee the reliability and functionality of software solutions.
        Proficiency in understanding, implementing, and troubleshooting various third-party APIs and internal services.
        Active participation in the documentation of APIs to ensure clarity and accessibility for users.
        A commitment to continuous learning, staying updated with new technologies, and seeking innovative approaches to deliver exceptional results.
        </p>
        <p className="about-me">
          <i>Specialties:</i> Laravel, PHP, MySql, React.js, JavaScript, Node.js, Express.js, MongoDB,
          Tailwind CSS,‌ ‌Git, Linux, Vim, Visual Studio Code, Readme API Documentation.
        </p>
        <p className="about-me">
         Outside of the office, I'm passionate about outdoor activities. I love camping, kayaking, and hiking. Music is a constant companion throughout my day.
         I also have a lovely cat, although I've always had a soft spot for dogs and hope to adopt one in the future.
         Collaborating on projects is another interest of mine, I use Linux as my go-to operating system, and of course, VIM.
         My latest endeavor involved working with my husband on an automated watering system using an Arduino for our indoor garden, where we're cultivating tomato plants!
         </p>
        <div>
          <a href="https://github.com/veroca88">
            <i className="fab fa-github fa-3x icon-skill"></i>
          </a>

          <a href="https://www.linkedin.com/in/veroca/">
            <i className="fab fa-linkedin-in fa-3x icon-skill"></i>
          </a>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}
